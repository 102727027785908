import Check from './Check';

const checkNumeric = function(numeric) {
    return !!numeric.match(/^\d+,\d{2,2}$/);
};
const checkNumericRepairable = function(numeric) {
    return !!numeric.match(/^\d+(,\d\d?)?$/);
};
const repairNumeric = function(numeric) {
    if (!checkNumeric(numeric) && checkNumericRepairable(numeric)) {
        if (numeric.match(/^\d+$/)) {
            numeric = numeric + ",00";
        } else if (numeric.match(/^\d+,\d$/)) {
            numeric = numeric + "0";
        }
    }
    return numeric;
}

export default new Check(checkNumeric, repairNumeric);
