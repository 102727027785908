import BSN from './checks/BSN';
import DateCheck from './checks/Date';
import Enum from './checks/Enum';
import Numeric from './checks/Numeric';
import Postalcode from './checks/Postalcode';

export default {
    BSN,
    Date: DateCheck,
    Enum,
    Numeric,
    Postalcode,
};
