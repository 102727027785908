<template>
    <div>
        <div v-if="showCheck">
            <disclaimer></disclaimer>
            <check></check>
        </div>
        <div v-else class="my-3">
            <div v-if="!allowed" class="alert alert-danger">
                <h4 class="my-3">Het is niet toegestaan deze code op uw eigen site te plaatsen.</h4>
            </div>
            <div v-if="!compatible" class="alert alert-danger">
                <h4 class="mt-3">Helaas, uw browser is niet geschikt.</h4>
                <p class="mt-3">
                    U kunt een andere browser proberen, zoals
                    <a href="https://www.mozilla.org/nl-NL/firefox/new/">Firefox</a>
                    of
                    <a href="https://www.google.com/chrome/">Chrome</a>.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import Check from './Check';
import Disclaimer from './Disclaimer';

const ALLOWED_DOMAIN_REGEX = /\.(koicontroleren\.nl|(atane|paneidos)\.dev)$/;

export default {
    components: { Disclaimer, Check },
    data() {
        const allowedOrigin = window.origin && window.origin.match(ALLOWED_DOMAIN_REGEX);
        const hostname = window.location.hostname;
        const host = window.location.host.split(":")[0];
        const allowedHost = (hostname || host).match(ALLOWED_DOMAIN_REGEX);
        return {
            compatible: window.File && window.FileReader && window.URL && window.Blob,
            allowed: allowedOrigin || allowedHost,
        };
    },
    computed: {
        showCheck() {
            return this.compatible && this.allowed;
        },
    },
};
</script>
