import Vue from 'vue';
import App from './components/App';
import Checks from './Checks';

window.Checks = Checks;

document.addEventListener('DOMContentLoaded', () => {
    const appDiv = document.getElementById('app');
    const app = new Vue({
        render: h => h(App)
      }).$mount()
      appDiv.appendChild(app.$el);
});
