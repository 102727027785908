import Format from "./Format";
import Checks from "../Checks";

const fields = [
    {
        name: 'K0001',
        label: 'K0001: Naam gastouderbureau',
        required: true,
    },
    {
        name: 'K0011',
        label: 'K0011: LRKP-nummer gastouderbureau',
        required: true,
        check: Checks.BSN,
    },
    {
        name: 'K0012',
        label: 'K0012: Jaarbedrag gastouder',
        required: true,
        check: Checks.Numeric
    },
    {
        name: 'K0701',
        label: 'K0701: Achternaam gastouder',
        required: true,
    },
    {
        name: 'K0702',
        label: 'K0702: Voorletters gastouder',
        required: true,
    },
    {
        name: 'K0703',
        label: 'K0703: Tussenvoegsels gastouder',
    },
    {
        name: 'K0704',
        label: 'K0704: BSN gastouder',
        required: true,
        check: Checks.BSN,
    },
    {
        name: 'K0705',
        label: 'K0705: Straatnaam gastouder',
        required: true,
    },
    {
        name: 'K0706',
        label: 'K0706: Huisnummer gastouder',
        required: true,
    },
    {
        name: 'K0707',
        label: 'K0707: Huisnummer-toevoeging gastouder'
    },
    {
        name: 'K0708',
        label: 'K0708: Postcode gastouder',
        required: true,
        check: Checks.Postalcode,
    },
    {
        name: 'K0709',
        label: 'K0709: Woonplaats gastouder',
        required: true,
    },
    {
        name: 'K0710',
        label: 'K0710: Geboortedatum kind',
        required: true,
        check: Checks.Date,
    },
];

const fieldNameToIndex = Format.makeFieldMap(fields);

function getHostRowMeta(row) {
    const initials = row[fieldNameToIndex.get('K0702')];
    const lastName = row[fieldNameToIndex.get('K0701')];
    return [{
        key: 'host',
        label: 'Gastouder',
        value: `${initials} ${lastName}`,
    }];
};

export default new Format(fields, getHostRowMeta);
