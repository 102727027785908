<template>
    <div>
        <form>
            <div class="form-group">
                <label for="file" class="h4">Selecteer hier uw bestand</label>
                <input type="file" id="file" class="form-control-file" ref="file">
            </div>
            <div>
                <button type="button" class="btn btn-primary btn-lg"
                    @click="checkFile"
                >Controleren</button>
                <a v-if="repairedURL" class="btn btn-outline-success btn-lg"
                 :href="repairedURL" :download="repairedName">Gerepareerde versie opslaan</a>
            </div>
        </form>

        <div v-if="checked" class="mt-4 my-2">
            <h4 class="p-1">Inhoud</h4>
            <table class="table table-sm w-auto">
                <tbody>
                    <tr>
                        <td>Type bestand:</td>
                        <td>{{ formatDescription }}</td>
                    </tr>
                    <tr>
                        <td>Regels:</td>
                        <td>{{ lines }}</td>
                    </tr>
                    <tr>
                        <td>Fouten:</td>
                        <td>{{ errorLines }}</td>
                    </tr>
                    <tr v-if="fullyRepairableErrorCount">
                        <td>Volledig herstelbaar:</td>
                        <td>{{ fullyRepairableErrorCount}}</td>
                    </tr>
                    <tr v-if="partialRepairableErrorCount">
                        <td>Gedeeltelijk herstelbaar:</td>
                        <td>{{ partialRepairableErrorCount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <table class="table" v-show="errors && errors.length">
            <thead>
                <tr>
                    <th>Regel</th>
                    <th>Entiteit</th>
                    <th>Veld</th>
                    <th>Fout</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="error in errors" :key="error.line">
                    <td>{{ error.line }}</td>
                    <td>
                        <div v-if="error.meta">
                            <div v-for="meta in error.meta" :key="`error_${error.line}_meta_${meta.key}`">
                                {{ meta.label }}: {{ meta.value }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div v-for="(check, index) in error.check" :key="`error_${error.line}_${index}`">
                            {{ check.field }}
                        </div>
                    </td>
                    <td>
                        <div v-for="(check, index) in error.check" :key="`error_${error.line}_${index}`">
                            {{ check.error }}
                            <em v-if="check.repairable">(repareerbaar)</em>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

import CSVFile from '../CSVFile';

export default {
    name: 'check',
    data() {
        return {
            checked: false,
            formatDescription: null,
            errorLines: 0,
            repairableErrors: 0,
            lines: 0,
            fullyRepairableErrorCount: 0,
            partialRepairableErrorCount: 0,
            errors: null,
            repairedURL: null,
            repairedName: null,
        };
    },
    methods: {
        async checkFile() {
            const file = this.$refs.file.files[0];
            if (file) {
                if (this.repairedURL) {
                    const url = this.repairedURL;
                    this.repairedURL = null;
                    URL.revokeObjectURL(url);
                }
                const csv = new CSVFile(file);
                const type = await csv.getFormatDescription();
                const report = await csv.check();

                this.formatDescription = type;
                this.lines = report.totalCount;
                this.errorLines = report.errorCount;
                this.errors = report.errors;
                this.repairableErrors = report.repairableErrorCount;
                this.fullyRepairableErrorCount = report.fullyRepairableErrorCount;
                this.partialRepairableErrorCount = report.partialRepairableErrorCount;
                if (report.fullyRepairableErrorCount | report.partialRepairableErrorCount) {
                    const repairedData = report.repaired;
                    const blob = new Blob([repairedData], {type: "text/plain" });
                    const url = URL.createObjectURL(blob);
                    this.repairedURL = url;
                    this.repairedName = "gerepareerd-" + file.name;
                }
                this.checked = true;
            }
        }
    }
};
</script>
