import Check from './Check';

export default new Check((bsn) => {
    if (typeof bsn === "undefined") {
        return false;
    }
    if (typeof bsn === 'number') {
        bsn = bsn.toString();
    }
    let csom = 0;           // variabele initialiseren
    let pos = bsn.length; // het aantal posities waaruit een bsn hoort te bestaan
    if (pos != 9) {
        return false;
    }
    for (let i = 0; i < 9; i++) {
        let num = bsn.substr(i, 1);       // bekijk elk karakter van de ingevoerde string
        if (pos == 1) { // BSN specifiek
            pos = -1;
        }
        csom += num * pos;  // bereken somproduct van het cijfer en diens positie
        pos--;                // naar de volgende positie
    }
    let mod = csom % 11;
    return csom > 0 && mod == 0;
}, (bsn) => {
    return ("0000000000000" + bsn).substr(-9);
});
