import formats from './Formats';

function readFileAsText(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const contents = event.target.result;
            resolve(contents);
        };
        reader.onerror = (e) => {
            reader.abort();
            reject(reader.error);
        }
        reader.onabort = (e) => {
            reject('aborted');
        }
        reader.readAsText(file);
    });
}

export default class CSVFile {
    constructor(file) {
        this.file = file;
    }

    async getFormatDescription() {
        const lines = await this.getLines();
        const firstLine = (lines[0] || '').split(";");
        const format = formats.filter(({ format }) => format.matches(firstLine))[0];
        return format ? format.name : 'Onbekend';
    }

    async getLines() {
        if (!this.lines) {
            try {
                const contents = await readFileAsText(this.file);
                this.lines = contents.split(/\r\n|\r|\n/);
            } catch(e) {
                this.lines = [];
            }
        }
        return this.lines;
    }

    async check() {
        const lines = await this.getLines();
        const firstLine = (lines[0] || '').split(";");
        const type = formats.filter(({ format }) => format.matches(firstLine))[0];
        if (!type) {
            return {
                error: 'Onbekend formaat',
            };
        }
        const { format } = type;
        const repairedRows = [firstLine];
        const records = lines.slice(1).map(line => line.split(";"));
        let errorCount = 0;
        let fullyRepairableErrorCount = 0;
        let partialRepairableErrorCount = 0;
        let repairableErrorCount = 0;
        let totalCount = 0;
        const errors = [];
        for(let i = 0; i < records.length; i++) {
            const record = records[i];
            if (record.length === 1 && record[0] === '' && (i + 1) === records.length) {
                continue;
            }
            totalCount += 1;
            if (record.length === 1 && record[0] === '') {
                errorCount += 1;
                repairableErrorCount += 1;
                fullyRepairableErrorCount += 1;
                errors.push({
                    line: i + 2,
                    check: [{ field: 'Lege regel', error: '' }],
                });
                continue;
            }
            const check = format.checkRow(record);
            if (check.length === 0) {
                repairedRows.push(record);
            } else {
                errorCount += 1;
                errors.push({
                    line: i + 2,
                    meta: format.getRowMeta(record),
                    check,
                });
                const repairedRow = format.repairRow(record);
                const repairedCheck = format.checkRow(repairedRow);
                repairedRows.push(repairedRow);
                if (repairedCheck.length === 0) {
                    repairableErrorCount += 1;
                    fullyRepairableErrorCount += 1;
                } else if(repairedCheck.length < check.length) {
                    partialRepairableErrorCount += 1;
                }
            }
        }

        const repaired = repairedRows.map(record => record.join(";")).join("\n") + "\n";

        return {
            totalCount,
            errorCount,
            repairableErrorCount,
            fullyRepairableErrorCount,
            partialRepairableErrorCount,
            errors,
            repaired,
        };
    }
}
