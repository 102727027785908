<template>
    <div class="card text-white bg-warning my-3">
        <div class="card-header">Disclaimer</div>
        <div class="card-body text-dark bg-white">
            <p>
                Uw bestand wordt <strong>niet</strong> geüpload, zodat wij (of anderen) de gegevens nooit zullen zien.
                Alle controles vinden <em>client-side</em> (op uw eigen computer) plaats. De resultaten van deze dienst kunnen afwijken van de
                resultaten van het aanleveren bij de Belastingdienst.
            </p>
        </div>
    </div>
</template>
<script>
export default {};
</script>
