import Check from './Check';

const checkPostcode = function(postcode) {
    return !!postcode.match(/^\d{4,4}[A-Z]{2,2}$/);
};
const checkPostcodeRepairable = function(postcode) {
    return !!postcode.match(/^\d{4,4}\s*[A-Z]{2,2}$/i);
}
const repairPostcode = function(postcode) {
    if (!checkPostcode(postcode) && checkPostcodeRepairable(postcode)) {
        postcode = postcode.replace(/\s*/g, '').toUpperCase();
    }
    return postcode;
};

export default new Check(checkPostcode, repairPostcode);
