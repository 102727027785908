export default class Format {
    constructor(fields, getRowMeta = null) {
        this.fields = fields;
        this.getRowMeta = getRowMeta || (() => null);
    }

    matches(header) {
        const expected = this.fields.map(field => field.name);
        if (header.length !== expected.length) {
            return false;
        }
        return expected.every((value, index) => value === header[index]);
    }

    checkRow(row) {
        // row is array
        if (row.length !== this.fields.length) {
            return [{ field: 'Aantal kolommen klopt niet', error: `ongeldig: ${row.length}` }];
        }
        const errors = [];

        for (let i = 0; i < row.length; i++) {
            const required = typeof this.fields[i].required === 'function' ? this.fields[i].required(row) : !!this.fields[i].required;
            if (required && row[i] === '') {
                errors.push({ field: this.fields[i].label || this.fields[i].name, error: 'ontbreekt' });
            } else {
                if (this.fields[i].check && row[i] !== '') {
                    if (!this.fields[i].check.valid(row[i])) {
                        errors.push({
                            field: this.fields[i].label || this.fields[i].name,
                            error: `ongeldig: ${row[i]}`,
                            repairable: this.fields[i].check.repairable(row[i]),
                        });
                    }
                }
            }
        }
        return errors;
    }

    repairRow(row) {
        return row.map((value, index) => {
            if (index < this.fields.length && this.fields[index].check && value && value !== '') {
                return this.fields[index].check.repair(value);
            }
            return value;
        });
    }

    static makeFieldMap(fields) {
        return fields.reduce((map, { name }, index) => {
            map.set(name, index);
            return map;
        }, new Map());
    }
}
