import Host from "./formats/Host";
import FamilyNew from "./formats/FamilyNew";
import FamilyOld from "./formats/FamilyOld";

export default [
    {
        format: Host,
        name: 'Jaarbedragen gastouders',
    },
    {
        format: FamilyNew,
        name: 'Verleende kinderopvang (vanaf 2020)',
    },
    {
        format: FamilyOld,
        name: 'Verleende kinderopvang (t/m 2019)',
    },
];
