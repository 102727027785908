import Check from './Check';

const DAYS_PER_MONTH_NORMAL_YEAR = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DAYS_PER_MONTH_LEAP_YEAR = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const checkDate = (date) => {
    const match = date.match(/^(\d{2,2})(\d{2,2})(\d{4,4})$/);
    if (!match) {
        return false;
    }
    const day = parseInt(match[1]);
    const month = parseInt(match[2]);
    const year = parseInt(match[3]);
    if (month < 1 || month > 12) {
        return false;
    }
    if (year < 1900) {
        return false;
    }
    let leap = false;
    if (year % 4 == 0) leap = true;
    if (year % 100 == 0) leap = false;
    if (year % 400 == 0) leap = true;
    const maxDay = (leap ? DAYS_PER_MONTH_LEAP_YEAR : DAYS_PER_MONTH_NORMAL_YEAR)[month];
    if (day < 1 || day > maxDay) {
        return false;
    }
    return true;
}

const checkDateRepairable = (date) => {
    date = date.replace(/[^0-9]/g, '');
    if (date.length < 8) {
        date = (new Array(9 - date.length)).join("0") + date;
    }
    return checkDate(date);
};
const repairDate = (date) => {
    if (!checkDate(date) && checkDateRepairable(date)) {
        date = date.replace(/[^0-9]/g, '');
        date = (new Array(9 - date.length)).join("0") + date;
    }
    return date;
};

export default new Check(checkDate, repairDate);
