import Format from "./Format";
import Checks from "../Checks";

let fieldNameToIndex = new Map();

const rowHasPartner = (row) => {
    const fields = [
        row[fieldNameToIndex.get('BSN partner')],
        row[fieldNameToIndex.get('Achternaam partner')],
        row[fieldNameToIndex.get('Voorletters partner')],
        row[fieldNameToIndex.get('Tussenvoegsels partner')],
        row[fieldNameToIndex.get('Geboortedatum partner')],
    ];
    return fields.filter(Boolean).length > 0;
};

const fields = [
    {
        name: 'BSN kind',
        required: true,
        check: Checks.BSN,
    },
    {
        name: 'Achternaam kind',
        required: true,
    },
    {
        name: 'Voorletters kind',
        required: true,
    },
    {
        name: 'Tussenvoegsels kind',
    },
    {
        name: 'Geboortedatum kind',
        required: true,
        check: Checks.Date,
    },
    {
        name: 'BSN betalende ouder',
        required: true,
        check: Checks.BSN,
    },
    {
        name: 'Achternaam betalende ouder',
        required: true,
    },
    {
        name: 'Voorletters betalende ouder',
        required: true,
    },
    {
        name: 'Tussenvoegsels betalende ouder',
    },
    {
        name: 'Geboortedatum betalende ouder',
        required: true,
        check: Checks.Date,
    },
    {
        name: 'BSN partner',
        required: rowHasPartner,
        check: Checks.BSN,
    },
    {
        name: 'Achternaam partner',
        required: rowHasPartner,
    },
    {
        name: 'Voorletters partner',
        required: rowHasPartner,
    },
    {
        name: 'Tussenvoegsels partner',
    },
    {
        name: 'Geboortedatum partner',
        required: rowHasPartner,
        check: Checks.Date,
    },
    {
        name: 'Periode levering',
        required: true,
        check: Checks.Period,
    },
    {
        name: 'Soort opvang',
        required: true,
        check: Checks.Enum(['DO', 'BSO']),
    },
    {
        name: 'Aantal afgenomen uren',
        required: true,
        check: Checks.Numeric,
    },
    {
        name: 'Gemiddeld uurtarief afgenomen uren',
        required: true,
        check: Checks.Numeric,
    },
    {
        name: 'LRK',
        required: true,
        check: Checks.BSN,
    },
    {
        name: 'Ingangsdatum contract',
        required: true,
        check: Checks.Date,
    },
    {
        name: 'Einddatum contract',
        check: Checks.Date,
    },
];

fieldNameToIndex = Format.makeFieldMap(fields);

function getFamilyNewRowMeta(row) {
    const initials = row[fieldNameToIndex.get('Voorletters betalende ouder')];
    const suffix = row[fieldNameToIndex.get('Tussenvoegsels betalende ouder')];
    const lastName = row[fieldNameToIndex.get('Achternaam betalende ouder')];

    const initialsChild = row[fieldNameToIndex.get('Voorletters kind')];
    const suffixChild = row[fieldNameToIndex.get('Tussenvoegsels kind')];
    const lastNameChild = row[fieldNameToIndex.get('Achternaam kind')];

    return [{
        key: 'family',
        label: 'Ouder',
        value: [initials, suffix, lastName].filter(Boolean).join(' '),
    }, {
        key: 'child',
        label: 'Kind',
        value: [initialsChild, suffixChild, lastNameChild].filter(Boolean).join(' '),
    }];
};

export default new Format(fields, getFamilyNewRowMeta);
