export default class Check {
    constructor(validator, repairer = null) {
        this.validator = validator;
        this.repairer = repairer || (value => value);
    }

    valid(value) {
        return this.validator(value);
    }

    repair(value) {
        if (!this.valid(value)) {
            const repaired = this.repairer(value);
            if (this.valid(repaired)) {
                return repaired;
            }
        }
        return value;
    }

    repairable(value) {
        return this.valid(this.repairer(value));
    }
}
