import Format from "./Format"
import Checks from "../Checks";

let fieldNameToIndex = new Map();

const rowIsHostCare = (row) => {
    const type1 = row[fieldNameToIndex.get('K0404')] === 'GO';
    const type2 = row[fieldNameToIndex.get('K0504')] === 'GO';
    return type1 === 'GO' || type2 === 'GO';
};

const rowIsNotHostCare = (row) => !rowIsHostCare(row);

const rowHasPartner = (row) => {
    const fields = [
        row[fieldNameToIndex.get('K0301')],
        row[fieldNameToIndex.get('K0302')],
        row[fieldNameToIndex.get('K0303')],
        row[fieldNameToIndex.get('K0304')],
        row[fieldNameToIndex.get('K0305')],
        row[fieldNameToIndex.get('K0306')],
        row[fieldNameToIndex.get('K0307')],
        row[fieldNameToIndex.get('K0308')],
        row[fieldNameToIndex.get('K0309')],
        row[fieldNameToIndex.get('K0310')],
    ];
    return fields.filter(Boolean).length > 0;
};

const rowHasPartnerIsHostCare = (row) => rowHasPartner(row) && rowIsHostCare(row);

const rowIsDayCare = (row) => {
    const fields = [
        row[fieldNameToIndex.get('K0401')],
        row[fieldNameToIndex.get('K0402')],
        row[fieldNameToIndex.get('K0403')],
        row[fieldNameToIndex.get('K0404')],
        row[fieldNameToIndex.get('K0405')],
        row[fieldNameToIndex.get('K0406')],
        row[fieldNameToIndex.get('K0407')],
    ];
    return fields.filter(Boolean).length > 0;
};

const rowIsOutsideSchoolCare = (row) => {
    const fields = [
        row[fieldNameToIndex.get('K0501')],
        row[fieldNameToIndex.get('K0502')],
        row[fieldNameToIndex.get('K0503')],
        row[fieldNameToIndex.get('K0504')],
        row[fieldNameToIndex.get('K0505')],
        row[fieldNameToIndex.get('K0506')],
        row[fieldNameToIndex.get('K0507')],
    ];
    return fields.filter(Boolean).length > 0;
};

const fields = [
    {
        name: "K0001",
        label: "K0001: Naam organisatie",
        required: true,
    },
    {
        name: "K0002",
        label: "K0002: KvK-nummer organisatie",
        required: true,
    },
    {
        name: "K0003",
        label: "K0003: Naam contactpersoon",
        required: true,
    },
    {
        name: "K0004",
        label: "K0004: Telefoonnummer contactpersoon",
        required: true,
    },
    {
        name: "K0005",
        label: "K0005: E-mailadres contactpersoon",
        required: true,
    },
    {
        name: "K0006",
        label: "K0006: Straatnaam organisatie",
        required: true,
    },
    {
        name: "K0007",
        label: "K0007: Huisnummer organisatie",
        required: true,
    },
    {
        name: "K0008",
        label: "K0008: Huisnummertoevoeging organisatie",
    },
    {
        name: "K0009",
        label: "K0009: Postcode organisatie",
        required: true,
        check: Checks.Postalcode,
    },
    {
        name: "K0010",
        label: "K0010: Woonplaats organisatie",
        required: true,
    },
    {
        name: "K0011",
        label: "K0011: LRK-nummer organisatie",
        check: Checks.BSN,
    },
    {
        name: "K0101",
        label: "K0101: Achternaam kind",
        required: true,
    },
    {
        name: "K0102",
        label: "K0102: Voorletters kind",
        required: true,
    },
    {
        name: "K0103",
        label: "K0103: Tussenvoegsels kind",
    },
    {
        name: "K0104",
        label: "K0104: Voornamen kind",
        required: true,
    },
    {
        name: "K0105",
        label: "K0105: Geboortedatum kind",
        required: true,
        check: Checks.Date,
    },
    {
        name: "K0106",
        label: "K0106: BSN kind",
        required: true,
        check: Checks.BSN,
    },
    {
        name: "K0201",
        label: "K0201: Achternaam ouder",
        required: true,
    },
    {
        name: "K0202",
        label: "K0202: Voorletters ouder",
        required: true,
    },
    {
        name: "K0203",
        label: "K0203: Tussenvoegsels ouder",
    },
    {
        name: "K0204",
        label: "K0204: BSN ouder",
        required: true,
        check: Checks.BSN,
    },
    {
        name: "K0205",
        label: "K0205: Straatnaam ouder",
        required: true,
    },
    {
        name: "K0206",
        label: "K0206: Huisnummer ouder",
        required: true,
    },
    {
        name: "K0207",
        label: "K0207: Huisnummertoevoeging ouder",
    },
    {
        name: "K0208",
        label: "K0208: Postcode ouder",
        required: true,
        check: Checks.Postalcode,
    },
    {
        name: "K0209",
        label: "K0209: Woonplaats ouder",
        required: true,
    },
    {
        name: "K0210",
        label: "K0210: Geboortedatum ouder",
        required: rowIsHostCare,
    },
    {
        name: "K0301",
        label: "K0301: Achternaam partner",
        required: rowHasPartner,
    },
    {
        name: "K0302",
        label: "K0302: Voorletters partner",
        required: rowHasPartner,
    },
    {
        name: "K0303",
        label: "K0303: Tussenvoegsels partner",
    },
    {
        name: "K0304",
        label: "K0304: BSN partner",
        required: rowHasPartner,
        check: Checks.BSN,
    },
    {
        name: "K0305",
        label: "K0305: Straatnaam partner",
        required: rowHasPartner,
    },
    {
        name: "K0306",
        label: "K0306: Huisnummer partner",
        required: rowHasPartner,
    },
    {
        name: "K0307",
        label: "K0307: Huisnummertoevoeging partner",
    },
    {
        name: "K0308",
        label: "K0308: Postcode partner",
        required: rowHasPartner,
        check: Checks.Postalcode,
    },
    {
        name: "K0309",
        label: "K0309: Woonplaats partner",
        required: rowHasPartner,
    },
    {
        name: "K0310",
        label: "K0310: Geboortedatum partner",
        required: rowHasPartnerIsHostCare,
        check: Checks.Date,
    },
    {
        name: "K0401",
        label: "K0401: Ingangsdatum dagopvang",
        required: rowIsDayCare,
        check: Checks.Date,
    },
    {
        name: "K0402",
        label: "K0402: Einddatum dagopvang",
        required: rowIsDayCare,
        check: Checks.Date,
    },
    {
        name: "K0403",
        label: "K0403: Soort dagopvang",
        required: rowIsDayCare,
        check: Checks.Enum(['DO']),
    },
    {
        name: "K0404",
        label: "K0404: Vorm dagopvang",
        required: rowIsDayCare,
        check: Checks.Enum(['GO', 'KC']),
    },
    {
        name: "K0405",
        label: "K0405: Aantal uren dagopvang",
        required: rowIsDayCare,
    },
    {
        name: "K0406",
        label: "K0406: Uitvoeringskosten dagopvang",
        forbidden: rowIsNotHostCare,
    },
    {
        name: "K0407",
        label: "K0407: Uurprijs dagopvang",
        required: rowIsDayCare,
    },
    {
        name: "K0501",
        label: "K0501: Ingangsdatum BSO",
        required: rowIsOutsideSchoolCare,
        check: Checks.Date,
    },
    {
        name: "K0502",
        label: "K0502: Einddatum BSO",
        required: rowIsOutsideSchoolCare,
        check: Checks.Date,
    },
    {
        name: "K0503",
        label: "K0503: Soort BSO",
        required: rowIsOutsideSchoolCare,
        check: Checks.Enum(['BSO', 'NO', 'VO', 'VVE']),
    },
    {
        name: "K0504",
        label: "K0504: Vorm BSO",
        required: rowIsOutsideSchoolCare,
        check: Checks.Enum(['GO', 'KC']),
    },
    {
        name: "K0505",
        label: "K0505: Aantal uren BSO",
        required: rowIsOutsideSchoolCare,
    },
    {
        name: "K0506",
        label: "K0506: Uitvoeringskosten BSO",
        forbidden: rowIsNotHostCare,
    },
    {
        name: "K0507",
        label: "K0507: Uurprijs BSO",
        required: rowIsOutsideSchoolCare,
    },
    {
        name: "K0601",
        label: "K0601: Naam opvanglocatie",
        required: rowIsNotHostCare,
    },
    {
        name: "K0602",
        label: "K0602: Straat opvanglocatie",
        required: rowIsNotHostCare,
    },
    {
        name: "K0603",
        label: "K0603: Huisnummer opvanglocatie",
        required: rowIsNotHostCare,
    },
    {
        name: "K0604",
        label: "K0604: Huisnummertoevoeging opvanglocatie",
    },
    {
        name: "K0605",
        label: "K0605: Postcode opvanglocatie",
        required: rowIsNotHostCare,
        check: Checks.Postalcode,
    },
    {
        name: "K0606",
        label: "K0606: Woonplaats opvanglocatie",
        required: rowIsNotHostCare,
    },
    {
        name: "K0607",
        label: "K0607: LRK-nummer opvanglocatie",
        required: rowIsNotHostCare,
        check: Checks.BSN,
    },
    {
        name: "K0701",
        label: "K0701: Achternaam gastouder",
        required: rowIsHostCare,
    },
    {
        name: "K0702",
        label: "K0702: Voorletters gastouder",
        required: rowIsHostCare,
    },
    {
        name: "K0703",
        label: "K0703: Tussenvoegsels gastouder",
    },
    {
        name: "K0704",
        label: "K0704: BSN gastouder",
        required: rowIsHostCare,
        check: Checks.BSN,
    },
    {
        name: "K0705",
        label: "K0705: Straat gastouder",
        required: rowIsHostCare,
    },
    {
        name: "K0706",
        label: "K0706: Huisnummer gastouder",
        required: rowIsHostCare,
    },
    {
        name: "K0707",
        label: "K0707: Huisnummertoevoeging gastouder",
    },
    {
        name: "K0708",
        label: "K0708: Postcode gastouder",
        required: rowIsHostCare,
        check: Checks.Postalcode,
    },
    {
        name: "K0709",
        label: "K0709: Woonplaats gastouder",
        required: rowIsHostCare,
    },
    {
        name: "K0710",
        label: "K0710: Geboortedatum gastouder",
        required: rowIsHostCare,
        check: Checks.Date,
    },
    {
        name: "K0711",
        label: "K0711: LRK-nummer gastouder",
        required: rowIsHostCare,
        check: Checks.BSN,
    },
];

fieldNameToIndex = Format.makeFieldMap(fields);

function getFamilyOldRowMeta(row) {
    const initials = row[fieldNameToIndex.get('K0202')];
    const suffix = row[fieldNameToIndex.get('K0203')];
    const lastName = row[fieldNameToIndex.get('K0201')];

    const initialsChild = row[fieldNameToIndex.get('K0104')] || row[fieldNameToIndex.get('K0102')];
    const suffixChild = row[fieldNameToIndex.get('K0103')];
    const lastNameChild = row[fieldNameToIndex.get('K0101')];

    return [{
        key: 'family',
        label: 'Ouder',
        value: [initials, suffix, lastName].filter(Boolean).join(' '),
    }, {
        key: 'child',
        label: 'Kind',
        value: [initialsChild, suffixChild, lastNameChild].filter(Boolean).join(' '),
    }];
};

export default new Format(fields, getFamilyOldRowMeta);
